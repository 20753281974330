<template>
  <div class="dialog">
    <!--
      Utiliser le slot="activator" sur un composant pour activer une balise
      HTML qui héritera du comportement de la modale.
     -->
    <div
      v-if="$slots.activator"
      class="dialog-activator"
      @click="handleOpen">
      <slot name="activator"></slot>
    </div>

    <transition
      name="fade"
      mode="out-in">
      <div
        v-if="isOpen"
        class="dialog-shadow"
        @click="isShadowClosable ? handleClose() : null">
      </div>
    </transition>

    <transition
      name="fade"
      mode="out-in">
      <div
        v-if="isOpen"
        class="dialog-window">

        <button
          v-if="closeAction === 'ICON'"
          :class="['close_icon', 'float-right', 'px-4', 'py-0']"
          @click="handleClose"
        />
        <!-- Contenu de la modale -->
        <slot/>

        <button
          v-if="closeAction === 'END_BUTTON'"
          class="btn btn-primary m-3 float-right"
          @click="handleClose">Fermer</button>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    closeAction: {
      type: 'ICON' | 'END_BUTTON' | 'NULL',
      default: 'END_BUTTON',
    },
    isShadowClosable: { //Permettre le clic de femeture en dehors de la modale
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    isOpen: false
  }),

  beforeMount() {
    if (this.visible) this.isOpen = true
  },

  watch: {
    visible(newVal) {
      this.isOpen = newVal
    }
  },

  methods: {
    handleClose () {
      this.isOpen = false
      this.$emit('update:visible', false)
    },

    handleOpen () {
      this.isOpen = true
      this.$emit('update:visible', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  &-activator {
    @apply cursor-pointer;
  }

  &-shadow {
    @apply fixed top-0 left-0 bottom-0 right-0 bg-black opacity-50 z-50;
  }

  &-window {
    @apply bg-white shadow-xl rounded-xl;
    @apply fixed z-50 max-w-full overflow-auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 710px;
    max-height: 95%;
  }
}

.close_icon {
  &:after {
    display: inline-block;
    content: "\00d7";
    color: #98A0A7;
    font-size: 22px;
  }
}
</style>
