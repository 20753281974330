import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $axios from './axios'
import firebaseMessaging from './firebase'

import Paginate from 'vuejs-paginate'
import PrimeVue from 'primevue/config';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueGtm from '@gtm-support/vue2-gtm';
import VueScrollTo from 'vue-scrollto'
import VueSocialSharing from 'vue-social-sharing'
import XLSX from 'xlsx'

import Blank from '@/layout/BlankLayout.vue'
import Default from '@/layout/DefaultLayout.vue'
import iconComponent from '@/components/commons/Icon'
import modalComponent from '@/components/commons/Modal'

import Calendar from 'primevue/calendar'
import Column from 'primevue/column'
import Datatable from 'primevue/datatable'
import Tooltip from 'primevue/tooltip'
import Skeleton from 'primevue/skeleton';

// Import des styles globaux
import '@/assets/styles/main.scss'

// Import de la librairie CSS Swiper
import 'swiper/css/swiper.css'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

// Import des styles pour la librairie Swiper > v6.0.0 = Ne pas passer en v6.0.0
// pour l'instant car il y a un bug sur les boutons de swipe, cf l'issue 680 :
// https://github.com/surmon-china/vue-awesome-swiper/issues/680#issuecomment-655928225
// import 'swiper/swiper-bundle.css'

Vue.config.productionTip = false

// Axios interceptor
$axios.interceptors.request.use((config) => {
  if (store.getters.accessToken && router.currentRoute.name !== 'login') {
    config.headers.Authorization = `Bearer ${store.getters.accessToken}`
  }
  return config
})

const responseInterceptor = $axios.interceptors.response.use(
  res => res,
  err => {
    const status = err.response.status
    if (status !== 403 && status !== 404 && status !== 401) {
      return Promise.reject(err)
    }

    $axios.interceptors.response.eject(responseInterceptor)

    if (status === 403) {
      router.push('/403')
    } else if(status === 404 && router.currentRoute.params.slug) {
      router.push('/404')
    } else if (status === 401) {
      if (store.getters.refreshToken) {
        const refreshToken = store.getters.refreshToken
        store.dispatch('refreshAccessToken', refreshToken)
        err.response.config.headers.Authorization = `Bearer ${store.getters.accessToken}`
        return $axios(err.response.config)
      }
    }
  }
)

Vue.prototype.$axios = $axios
Vue.prototype.$messaging = firebaseMessaging

// https://lovemewithoutall.github.io/it/json-to-excel
// https://github.com/SheetJS/sheetjs
Vue.prototype.$xlsx = XLSX

// Custom layouts
Vue.component('default-layout', Default)
Vue.component('blank-layout', Blank)

// Import des icones custom depuis le dossier d'icone pour un usage global de ce
// composant. Ainsi, pas besoin de l'appeler à chaque fois. Il faut ensuite
// renseigner le nom de l'icone (idem au nom fichier) passée dans les props.
Vue.component('icon', iconComponent)
Vue.component('modal', modalComponent)

// Import du composant de pagination : https://github.com/lokyoung/vuejs-paginate#readme
Vue.component('v-paginate', Paginate)

Vue.use(PrimeVue, {
  locale: {
    firstDayOfWeek: 1,
    dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
    monthNames: [ "Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Decembre" ],
    monthNamesShort: [ "Jan", "Fév", "Mar", "Avr", "Mai", "Juin","Juil", "Août", "Sept", "Oct", "Nov", "Dec" ],
    today: 'Aujourd`\'hui',
    clear: 'Effacer',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Wk'
  }
});
Vue.component('Calendar', Calendar);
Vue.component('PrimeColumn', Column);
Vue.component('PrimeDatatable', Datatable);
Vue.directive('tooltip', Tooltip);
Vue.component('Skeleton', Skeleton);


// Vue-awesome-swiper doc here : https://github.com/surmon-china/vue-awesome-swiper
Vue.use(VueAwesomeSwiper /* { default options with global component } */)

// Vue-social-sharing : https://www.npmjs.com/package/vue-social-sharing
Vue.use(VueSocialSharing)

// Vue GTAG : https://matteo-gabriele.gitbook.io/vue-gtag/v/master/
Vue.use(VueGtm, {
  id: 'GTM-WHHX5NT',
  // queryParams: {
  //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
  //   gtm_preview: 'env-4',
  //   gtm_cookies_win: 'x'
  // },
  defer: false,
  compatibility: false,
  enabled: true,
  debug: true,
  loadScript: true,
  vueRouter: router,
  ignoredViews: [],
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});

// NPM package pour scroller : https://www.npmjs.com/package/vue-scrollto
Vue.use(VueScrollTo)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
