<template>
    <modal :visible.sync="isVisible" :close-action="'NULL'" v-if="isModalOpen">
      <div :class="['container', 'relative']">
        <!-- <section :class="['container-header', 'mb-5']">
          <div :class="['w-full']">
            <div :class="['scroller', 'overflow-y-auto', 'bg-primary-lighter', 'm-12', 'p-8']">
              <div v-html="modalText"></div>
              <br>
              <br>
            </div>
          </div>
        </section> -->
        <section :class="['container-header', 'my-10']">
          <h1 :class="['container-header--title--secondary']">
            <span :class="['bold']" v-html="modalTextTitle" />
          </h1>
          <hr :class="['absolute', 'w-full']" :style="{ top: '80px', left: '0', right: '0' }" />
        </section>

        <div class="flex justify-center items-center my-5" v-if="modalTextImg">
          <img :src="modalTextImg" :class="['icon', 'icon-xl', 'ml-0']" alt="Image de la notification">
        </div>

        <div class="p-5 text-center m-0" v-html="modalTextBody" />

        <div :class="['flex', 'items-center', 'row', 'justify-center', 'my-10']">
          <button
            :class="['btn', 'btn-secondary', 'mx-5']"
            @click="closeModal"
          >
            Fermer
          </button>
        </div>
      </div>
    </modal>
</template>

<script>
import axios from '@/axios'
import { useFirebase } from '../../../plugins/utils/notificationFirebase'
import Modal from '@/components/commons/Modal'

export default {
  components: {
    Modal
  },

  data: () => ({
    isModalOpen: false,
    isVisible: false,
  }),

  async beforeMount() {
    this.isVisible = this.isModalOpen
  },

  mounted() {
    this.firebaseUtils = useFirebase(axios, this.$messaging)
    this.firebaseUtils.startListeners(this.openModal)
  },

  methods: {
    openModal(payload) {
      this.modalTextTitle = payload.data.title
      this.modalTextBody = payload.data.body
      this.modalTextImg = payload.data.picture

      this.isModalOpen = true
      this.isVisible = true
      this.$emit('update:isOpen', true)
    },
    closeModal() {
      this.isVisible = false
      this.isModalOpen = false
    }
  }
}
</script>